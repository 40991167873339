<template>
  <div :class="CSSClasses" :style="CSS + ';display: inline-block;height:' + height + ';width:' + width"  v-show="!isHidden">
    <el-image
      :src="urlImage"
      :name="name"
      :fit="fit.fit">
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
export default {
  name: 'a-image',
  inject: {
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin, VisibleMixin],
  props: {
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    fit: {
      type: [Object, String],
      editor: 'ImageFit',
      description: 'Заполнение',
      default: () => {
        return {
          type: null,
          registryId: null,
          fit: 'none',
          fieldId: null
        }
      }
    },
    url: {
      type: String,
      editor: 'Link',
      description: 'Изображение'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    height: {
      type: String,
      description: 'Высота'
    },
    isSaveInBase64: {
      type: Boolean,
      description: 'Для экспорта',
      options: {
        tooltip: {
          show: true,
          content: 'Во включенном состоянии хранится в base64 и экспортируется вместе с файлом шаблона'
        }
      }
    },
    formatInBase64: {
      type: String,
      default: () => null,
      frozen: true
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  computed: {
    urlImage () {
      if (this.formatInBase64) {
        return this.formatInBase64
      }
      if (this.url) {
        if (this.url.startsWith('http')) {
          return this.url
        } else {
          return `${this.$config.api}${this.url}`
        }
      } else {
        return this.image
      }
    },
    image () {
      let answer = null
      if (this.fit.fieldId && (this.getModel()[`attr_${this.fit.fieldId}_`] || this.getModel()[this.fit.fieldId])) {
        try {
          let parseImage = JSON.parse(this.getModel()[`attr_${this.fit.fieldId}_`] ?? this.getModel()[this.fit.fieldId])
          if (parseImage && parseImage.length) {
            parseImage.forEach(item => {
              if (RegExp(/(png|svg|jpg|jpeg|gif)$/i).test(item.extension)) {
                answer = `${this.$config.api}/files/${this.getFilePath(item)}`
              }
            })
          }
        } catch (error) {
          console.log(error)
        }
      }

      return answer
    }
  },
  watch: {
    isSaveInBase64 (newValue) {
      if (newValue && this.urlImage) {
        this.toDataURL(this.urlImage)
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  methods: {
    // перевести изображение в base64
    toDataURL (url) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.addEventListener('load', () => {
            this.$emit('change-property', { name: 'formatInBase64', value: reader.result })
          }, { once: true })
        })
    }
  }
}
</script>

<style scoped>
</style>
